module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1f403fa873b03a4bacbdd76cafdac412"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"brantt","accessToken":"MC5ZSDlYTmhFQUFDUUFMUFZs.77-9ce-_ve-_vSVU77-977-9Ee-_ve-_vUs977-977-9AjLvv73vv71Z77-9bEHvv73vv71aM--_ve-_vVPvv73vv70","schemas":{"homepage":{"Main":{"title":{"type":"StructuredText","config":{"single":"heading1","label":"Title"}},"slider":{"type":"Group","config":{"fields":{"image":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"Image"}},"title1":{"type":"StructuredText","config":{"single":"heading1","label":"Title"}},"content":{"type":"StructuredText","config":{"single":"paragraph","label":"Content"}},"button_text":{"type":"StructuredText","config":{"single":"paragraph","label":"Button text"}},"button_link":{"type":"Link","config":{"allowTargetBlank":true,"label":"Button link"}}},"label":"Slider"}},"process":{"type":"Group","config":{"fields":{"title1":{"type":"StructuredText","config":{"single":"heading1","label":"Title"}},"description":{"type":"StructuredText","config":{"single":"paragraph","label":"Description"}}},"label":"Process"}},"quotes":{"type":"Group","config":{"fields":{"quote":{"type":"StructuredText","config":{"single":"paragraph","label":"Quote"}},"author":{"type":"StructuredText","config":{"single":"paragraph","label":"Author"}}},"label":"Quotes"}}}}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
